<app-phx-modal
  #modal
  [buttons]="modalButtons"
  [showCloseButton]="true"
  title="Override document rule"
>
  <div class="row">
    <div class="col-xs-12">
      <div class="document-file-compliance-rule">
        <strong>{{ruleInfo.rulesetName}}</strong>
        <div class="compliance-rule">
          <app-rule-compliance-status-icon
            [valid]="ruleInfo.validBySystem"
            [ruleLevel]="ruleInfo.executedRuleLevel"
            [showOverride]="false"
          >
          </app-rule-compliance-status-icon>
          {{ruleInfo.ruleText}}
        </div>              
      </div>
    </div>
  </div>

  <form
    *ngIf="form"
    [formGroup]="form"
  >
    <div class="row">
        <phx-form-control
          [control]="form.controls.reasonCode"
          [layoutType]="layoutType"
          labelText="Reason to override rule"
        >
          <app-phx-select-box
            [items]="overrideReasons"
            textField="DisplayText"
            valueField="Code"
            [formControl]="form.controls.reasonCode"
          >
          </app-phx-select-box>
        </phx-form-control>

        <phx-form-control
          [control]="form.controls.reasonText"
          [layoutType]="layoutType"
          [labelText]="form.value.reasonCode === reasonCodeOther ? 'Other reason(s)' : 'Note (optional)'"
        >
          <textarea
            class="form-control"
            [formControl]="form.controls.reasonText"
            placeholder="Please write your other reason(s) to override the document rule"
            [maxLength]="reasonTextMaxLength"
          >
          </textarea>
        </phx-form-control>
    </div>
  </form>
  <div class="row margin-10-v-t">
    <div class="col-xs-12">
      Would you like to override the rule?
    </div>
  </div>
</app-phx-modal>
