import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DocumentComplianceEntityInformation } from '../../../model/document-compliance/document-compliance-entity-information';
import { from, Subject } from 'rxjs';
import { ApiService, CommonService, LoadingSpinnerService } from '../../..';
import { PhxConstants } from '../../../model/phx-constants';
import { ComplianceDataService } from '../../../data-services/compliance-data/compliance-data.service';
import { RuleComplianceResults, RuleComplianceRuleResult, ComplianceDataUpdatedEvent } from '../../models';
import { RuleOverrideRequest, RuleOverrideSubmission } from '../../internal-models';

@Component({
  selector: 'app-rule-compliance-results-table',
  templateUrl: './rule-compliance-results-table.component.html',
  styleUrls: ['./rule-compliance-results-table.component.less']
})
export class RuleComplianceResultsTableComponent {
  @Input() complianceData: RuleComplianceResults;
  @Input() isDocumentView = false;
  @Input() additionalInformation: DocumentComplianceEntityInformation = null;
  @Input() complianceDocumentId: number;
  @Input() complianceDocumentStatusId: number;
  @Input() documentName: string = null;
  @Output() complianceDataUpdated = new EventEmitter<ComplianceDataUpdatedEvent>();

  public readonly complianceDocumentStatus = PhxConstants.ComplianceDocumentStatus;

  public readonly toggleComplianceRuleOverrideModal$ = new Subject<RuleOverrideRequest>();

  constructor(
    private readonly commonService: CommonService,
    private readonly apiService: ApiService,
    private readonly complianceDataService: ComplianceDataService,
    private readonly loadingSpinnerService: LoadingSpinnerService
  ) {}

  reAnalyzeDocument() {
    const additionalInformation = {
      ...this.additionalInformation,
      FilePublicId: this.complianceData.filePublicId // Add this to the API payload without mutating the input additionalInformation
    };

    const commandRequest = {
      CommandName: 'ComplianceDocumentRunRule',
      ComplianceDocumentId: this.complianceDocumentId,
      AdditionalInformation: JSON.stringify(additionalInformation)
    };
    let html = '<div class="gvlogo-for-toast">';
    html += '<div class="gv-logo-container"><img src="/assets/logos/gv-logo.svg"></div>';
    html += `<div class='text-container'>Analyzing <i>${this.documentName}</i></div>`;
    html += '<div>';

    this.commonService.logWarningNoIcon(html, true);
    from(this.apiService.command('ComplianceDocumentRunRule', commandRequest, false))
    .subscribe({
      next: () => {
        this.emitComplianceDataUpdated(`${this.documentName}</i> re-analyzed`);
      },
      error: () => {
        this.commonService.logError('Error re-analyzing document compliance rules');
      }
    });
  }

  overrideRule(rule: RuleComplianceRuleResult) {
    this.toggleComplianceRuleOverrideModal$.next({ 
      rulesetName: this.complianceData.ruleSubCategory,
      ruleId: rule.id,
      ruleText: rule.infoMessage,
      validBySystem: rule.ruleComplianceOverride?.validBySystem ?? rule.isValid,
      executedRuleLevel: rule.executedRuleLevel
    });
  }

  createRuleOverride(event: RuleOverrideSubmission) {
    this.loadingSpinnerService.show();

    this.complianceDataService.createComplianceDataRuleOverride(
      this.complianceData.id,
      event.ruleId,
      'ComplianceDocuments',
      this.complianceDocumentId.toString(),
      event.overrideData
    )
    .subscribe({
      next: () => {
        this.emitComplianceDataUpdated('Rule overriden');
        this.loadingSpinnerService.hide();
      },
      error: error => {
        this.loadingSpinnerService.hide();
        throw error;
      }
    });
  }

  resetRuleOverride(id: string) {
    this.loadingSpinnerService.show();

    this.complianceDataService.deleteComplianceRuleOverride(
      id,
      'ComplianceDocuments',
      this.complianceDocumentId.toString()
    ).subscribe({
      next: () => {
        this.emitComplianceDataUpdated('Rule override reset');
        this.loadingSpinnerService.hide();
      },
      error: error => {
        this.loadingSpinnerService.hide();
        throw error;
      }
    });
  }

  private emitComplianceDataUpdated(message: string = '') {
    this.complianceDataUpdated.emit({
      documentName: this.documentName,
      filePublicId: this.complianceData.filePublicId,
      message
    });
  }
}
