import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PhxModalComponent } from '../../../components/phx-modal/phx-modal.component';
import { BaseComponentOnDestroy } from '../../../epics/base-component-on-destroy';
import { PhxButton, PhxFormControlLayoutType } from '../../../model';
import { ToTypedFormGroup } from '../../../model/to-typed-form-group.model';
import { RuleOverrideReason, RuleOverrideRequest, RuleOverrideSubmission } from '../../internal-models';
import { COMPLIANCE_RULE_OVERRIDE_REASONS, ComplianceRuleOverrideReasons} from 'src/app/common/services/compliance-rule-override-reasons.provider';

@Component({
  selector: 'app-rule-compliance-override-modal',
  templateUrl: './rule-compliance-override-modal.component.html',
  styleUrls: ['./rule-compliance-override-modal.component.less']
})
export class RuleComplianceOverrideModalComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() showModal$: Observable<RuleOverrideRequest>;
  @Output() submitOverride = new EventEmitter<RuleOverrideSubmission>();

  @ViewChild('modal', { static: false }) modal: PhxModalComponent;

  public readonly layoutType = PhxFormControlLayoutType.Stacked;
  public readonly reasonTextMaxLength = 100;

  public ruleInfo: RuleOverrideRequest = {
    ruleId: '',
    rulesetName: '',
    ruleText: '',
    validBySystem: false,
    executedRuleLevel: ''
  };

  public readonly modalButtons: PhxButton[] = [
    {
      icon: null,
      tooltip: $localize`:@@common.button.submit:Submit`,
      btnType: 'primary',
      action: () => {
        this.submitOverride.emit({
          ruleId: this.ruleInfo.ruleId,
          overrideData: this.form.getRawValue()
        });
        this.modal.hide();
      },
      disabled: () => this.form?.invalid
    },
    {
      icon: null,
      tooltip: $localize`:@@common.button.cancel:Cancel`,
      btnType: 'default',
      action: () => {
        this.modal.hide();
      }
    }
  ];

  public form: FormGroup<ToTypedFormGroup<RuleOverrideFormData>>;

  public overrideReasons: RuleOverrideReason[] = [];
  public readonly reasonCodeOther = 'Other';

  constructor(@Inject(COMPLIANCE_RULE_OVERRIDE_REASONS) private complianceRuleOverrideReasons$: Observable<ComplianceRuleOverrideReasons>) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.subscribeToShowModal();
  }

  private subscribeToShowModal(): void {
    
    combineLatest([this.showModal$, this.complianceRuleOverrideReasons$])
    .pipe(takeUntil(this.isDestroyed$))
    .subscribe(([event, complianceRuleOverrideReasons]) => {
      const { 
        ManualFailReasons: manualFailReasons,
        ManualPassReasons: manualPassReasons 
      } = complianceRuleOverrideReasons;

      this.ruleInfo = event;
      this.form.reset({ isValid: !event.validBySystem });

      // Clone the reasons from the input
      const overrideReasons = (event.validBySystem ? manualFailReasons : manualPassReasons)
        .map(reason => ({ ...reason }));

      // Add the 'Other' reason to the list
      overrideReasons.push({ Code: 'Other', DisplayText: 'Other' });

      this.overrideReasons = overrideReasons;

      this.modal.show();
    });
  }

  private initForm(): void {
    this.form = new FormGroup<ToTypedFormGroup<RuleOverrideFormData>>({
      isValid: new FormControl(null, Validators.required),
      reasonCode: new FormControl(null, Validators.required),
      reasonText: new FormControl(null) // set these valdiators conditionally
    });

    this.form.controls.reasonCode.valueChanges
      .subscribe(reasonCode => {
        const reasonTextControl = this.form.controls.reasonText;
        const validators: ValidatorFn[] = [Validators.maxLength(this.reasonTextMaxLength)];

        if (reasonCode === this.reasonCodeOther) {
          validators.push(Validators.required);
        }

        reasonTextControl.setValidators(validators);
        reasonTextControl.updateValueAndValidity();
      });
  }
}

export interface RuleOverrideFormData {
  isValid: boolean;
  reasonCode: string;
  reasonText: string;
}

