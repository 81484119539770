<div *ngIf="isDocumentView
    && [complianceDocumentStatus.New, 
    complianceDocumentStatus.PendingUpload, 
    complianceDocumentStatus.PendingSystem, 
    complianceDocumentStatus.PendingReview, 
    complianceDocumentStatus.Active].includes(complianceDocumentStatusId)" 
     class="re-analyze-button-container"
>
    <button 
        class="btn btn-warning"
        (click)="reAnalyzeDocument()">
        Re-analyze document
    </button>
</div>

<div class="rule-results-container">
    <cdk-accordion class="rule-results-accordion">
        <cdk-accordion-item
            *ngFor="let rule of complianceData.ruleResults; let idx = index"
            #accordionItem="cdkAccordionItem"
            class="rule-results-accordion-item"
            tabindex="0"
            [attr.id]="'accordion-header-' + idx"
            [attr.aria-expanded]="accordionItem.expanded"
            [attr.aria-controls]="'accordion-body-' + idx"
        >
            <div class="rule-results-accordion-item-container">
                <div class="rule-results-accordion-item-header">
                    <div class="col-status">
                        <app-rule-compliance-status-icon
                            [valid]="rule.isValid"
                            [ruleLevel]="rule.executedRuleLevel"
                            [overriden]="rule.ruleComplianceOverride?.isApplicable"
                        >
                        </app-rule-compliance-status-icon>
                    </div>
                    <div class="col-message">
                        {{rule.infoMessage}}
                        <button
                            mat-button
                            color="primary"
                            class="expand-button mat-small"
                            (click)="accordionItem.toggle()"
                            *ngIf="rule.operation || rule.ruleComplianceOverride?.isApplicable"
                        >
                            <span>details</span>
                            <mat-icon iconPositionEnd>{{accordionItem.expanded ? 'expand_less' : 'expand_more'}}</mat-icon>
                        </button>
                    </div>
                    <div class="col-actions">
                        <button 
                            mat-stroked-button
                            color="primary"
                            class="mat-small"
                            *ngIf="rule.ruleComplianceOverride"
                            (click)="resetRuleOverride(rule.ruleComplianceOverride.id)">
                            Reset
                        </button>
                        <button 
                            mat-stroked-button
                            color="primary"
                            class="mat-small"
                            *ngIf="!rule.ruleComplianceOverride"
                            (click)="overrideRule(rule)">
                            {{ rule.isValid ? 'Override' : 'Approve' }}
                        </button>
                    </div>
                </div>
                <section class="rule-results-accordion-item-body"
                    [style.display]="accordionItem.expanded ? '' : 'none'"
                    [attr.id]="'accordion-body-' + idx"
                    [attr.aria-labelledby]="'accordion-header-' + idx"
                >
                    <div class="info-table-container">
                        <app-rule-compliance-info-table [rule]="rule"></app-rule-compliance-info-table>
                    </div>
                </section>
                <mat-divider></mat-divider>
            </div>
        </cdk-accordion-item>
    </cdk-accordion>
</div>

<app-rule-compliance-override-modal 
    [showModal$]="toggleComplianceRuleOverrideModal$.asObservable()"
    (submitOverride)="createRuleOverride($event)"
>
</app-rule-compliance-override-modal>
